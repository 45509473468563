import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Sidebar from "./components/sidebar";
import Dashboard from "./components/dashboard";
import Chatbot from "./components/chatbot";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Sidebar />
        <div
          style={{
            width: "calc(100vw - 280px)",
            height: "100vh",
            position: "fixed",
            left: 280,
            top: 0,
            padding: "32px",
          }}
        >
          <Routes>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/chatbot" element={<Chatbot />} />
            <Route path="/" element={<Navigate to="/dashboard" />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
