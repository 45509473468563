import React, { useState } from "react";
import { Sidenav, Nav } from "rsuite";
import { useNavigate } from "react-router-dom";

import DashboardIcon from "@rsuite/icons/legacy/Dashboard";
import WechatOutlineIcon from "@rsuite/icons/WechatOutline";

const Sidebar = () => {
  const navigate = useNavigate();
  const [item, setItem] = useState(1);
  const routingDashboard = () => {
    setItem(1);
    navigate("/dashboard");
  };
  const routingChatbot = () => {
    setItem(2);
    navigate("/chatbot");
  };
  return (
    <div style={{ width: 280, height: "100vh" }}>
      <Sidenav appearance="inverse" style={{ height: "100%" }}>
        <Sidenav.Header>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyItems: "center",
              padding: 20,
              fontSize: 24,
            }}
          >
            Portfolio Management
          </div>
        </Sidenav.Header>
        <Sidenav.Body>
          <Nav>
            <Nav.Item
              eventKey="1"
              active={item === 1}
              icon={<DashboardIcon />}
              onClick={routingDashboard}
            >
              Dashboard
            </Nav.Item>
            <Nav.Item
              eventKey="2"
              active={item === 2}
              icon={<WechatOutlineIcon />}
              onClick={routingChatbot}
            >
              Chatbot
            </Nav.Item>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </div>
  );
};

export default Sidebar;
