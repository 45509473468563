import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import _ from "lodash";
import apiUrl from "./../utils/api";

const Dashboard = () => {
  const [seriesScatter, setSeriesScatter] = useState([]);
  const [seriesRadar, setSeriesRadar] = useState([]);
  const getNews = async () => {
    const params = {
      start: "2023-9-15T00:00:00Z",
      end: "2023-10-15T00:00:00Z",
    };
    try {
      const response = await axios.get(`${apiUrl}/get_all`, {
        params,
      });
      const temp = [];
      const tmp = response.data;
      _.map(tmp, (dat) => {
        temp.push(dat.count);
        delete dat.count;
      });
      setSeriesScatter(tmp);
      setSeriesRadar(temp);
    } catch {}
  };
  useEffect(() => {
    getNews();
  }, []);
  const optionsScatter = {
    chart: {
      type: "scatter",
      zoom: {
        type: "xy",
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      min: -1,
      max: 1,
      tickAmount: 2,
      labels: {
        formatter: function (value) {
          if (value === 0) {
            return "Neutral";
          } else if (value === -1) {
            return "Negative";
          } else if (value === 1) {
            return "Positive";
          } else {
            return "";
          }
        },
      },
    },
  };
  const optionsRadar = {
    chart: {
      type: "radar",
    },
    xaxis: {
      categories: ["Negative", "Positive", "Neutral", "Total"],
    },
    yaxis: {
      min: 0,
      max: 500,
      tickAmount: 4,
    },
  };
  console.log(seriesScatter);
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
      <Chart
        options={optionsScatter}
        series={seriesScatter}
        type="scatter"
        height={250}
        width={600}
      />
      <Chart
        options={optionsRadar}
        series={seriesRadar}
        type="radar"
        height={350}
        width={600}
      />
    </div>
  );
};

export default Dashboard;
